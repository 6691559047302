<template>
  <div>
    <!-- loading dialog displayed when changing solution and reloading the page-->
    <c-modal v-model="showLoadingModal">
      <template v-slot:body>
        <c-typography center>Loading</c-typography>
        <div class="c-text-center c-mt3 c-px4">
          <c-circular-progress color="#1F2929" size="xs" />
        </div> </template
    ></c-modal>

    <div id="headerNav">
      <div class="hidden">
        <!-- This is used only for authentication on knowledge base and must be hidden -->
        <!-- It is imported on 'main.ts' using 'app.use(AnnounceKit)' -->
        <AnnounceKit widget="https://support.enlyft.com/widgets/v2/4B6XXG" :user="getUserData()" :data="getSegmentationData()" />
      </div>
      <refresh-bar v-if="showRefreshBar" />
      <c-s-user-bar v-if="showCSUserBar" :teamName="activeTeam.account_name" />
      <unsupported-browser-bar v-if="showUnsupportedBrowserBar" />
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters, mapState } from "vuex";
import { Rights, User } from "@/shared/user";
import utils from "@/shared/utils";
import { CModal, CTypography, CCircularProgress } from "@enlyft/design-system-v2";
import RefreshBar from "./RefreshBar.vue";
import CSUserBar from "./CSUserBar.vue";
import UnsupportedBrowserBar from "./UnsupportedBrowserBar.vue";

/* Pinia Store References */
import { useSavedAccounts } from "@/stores/saved-accounts/store";
import { useSavedContacts } from "@/stores/saved-contacts/store";
import { useIntegrations } from "@/stores/integrations/store";
import { routeToStayOn } from "./HelperComponentUtils";
import { mapStores } from "pinia";
import { useDiscover } from "@/stores/discover/store";
import { useUser } from "@/stores/user/store";

let unsubscribeModelSelection: () => void;
let unsubscribeTeamSelection: () => void;

// This file used to be called NavBar.vue, it is from before the UI revamp brought by Nayan.
// The previous UI used to have the navbar in ALL PAGES, so it was a good way to put util code that needed to be present everywhere in the app.
// In the new UI, there is no navbar, but we needed to keep this component because it has team and model mutation subscriptions
// We decided to keep it as a helper component due to difficulties to find another place for its logic

export default {
  name: "HelperComponent",
  components: {
    CModal,
    CCircularProgress,
    CTypography,
    RefreshBar,
    CSUserBar,
    UnsupportedBrowserBar,
  },
  data(): {
    isLoading: boolean;
    hostUrl: string;
    trackButtonClick: () => void;
    badgeStyle: any;
    beamer: any;
    userAgent?: string;
    showLoadingModal: boolean;
  } {
    return {
      isLoading: false,
      hostUrl: process.env.VUE_APP_HOST_URL || window.location.origin,
      trackButtonClick: utils.trackButtonClick,
      badgeStyle: {
        top: "-4px",
        left: "-7px",
        cursor: "pointer",
      },
      beamer: undefined,
      userAgent: undefined,
      showLoadingModal: false,
    };
  },
  computed: {
    ...mapStores(useDiscover, useUser),
    ...mapState("login", {
      userTeams: "teams",
      user: "user",
      redirect: "redirect",
    }),
    ...mapState("team", {
      activeTeam: "activeTeam",
    }),
    ...mapState("model", {
      activeModel: "activeModel",
      teamModels: "activeTeamModels",
    }),
    ...mapGetters({
      accountId: "login/accountId",
      teamName: "team/activeTeamName",
      teamId: "team/activeTeamId",
      loggedInUser: "login/getUser",
      activeTeam: "team/activeTeam",
      featuresLimit: "team/featuresLimit",
    }),
    showRefreshBar(): boolean {
      return this.$store.state.needRefreshBar && this.loggedInUser && this.$route.name !== "loginEmail" && this.$route.name !== "landing";
    },
    showUnsupportedBrowserBar(): boolean {
      return !!(
        this.userAgent &&
        (this.userAgent.indexOf("Trident") > -1 ||
          this.userAgent.indexOf("MSIE") > -1 ||
          this.userAgent.indexOf("Edge/18.") > -1 ||
          this.userAgent.indexOf("Edge/17.") > -1 ||
          this.userAgent.indexOf("Edge/16.") > -1 ||
          this.userAgent.indexOf("Edge/15.") > -1)
      );
    },
    showCSUserBar() {
      return this.activeTeam?.role === "enlyft_cs" && !this.$route.path?.includes("/teamSelection");
    },
    isAdminOrManager: function () {
      return this.activeTeam.role === "admin" || this.activeTeam.role === "viewer" || this.activeTeam.role === "enlyft_cs";
    },
  },
  created() {
    try {
      if (this.loggedInUser) {
        this.beamer = (window as any).Beamer;
        try {
          utils.setUserProperties(this.loggedInUser, this.activeTeam);
          utils.startGoogleAnalytics(this.loggedInUser, this.activeTeam);
          utils.setMixPanelUserProperties(this.loggedInUser, this.activeTeam);

          if (this.activeTeam && this.activeTeam.account_name && (window as any).mixpanel && (window as any).mixpanel.app) {
            (window as any).mixpanel.app.set_group("Subscription", this.activeTeam.account_name);
          }
        } catch (e) {
          console.error("Unknown APM failure " + e);
        }
      }
      this.userAgent = navigator.userAgent;
    } catch (e) {
      console.error("unknown analytics failure", e);
    }
  },
  async mounted() {
    // Force user data (login + users api) sync
    await this.updateUserData();
    unsubscribeModelSelection = this.$store.subscribe(async (mutations, state) => {
      if (!mutations.type || !mutations.payload) {
        return;
      }

      let totalOfferings = this.teamModels && Array.isArray(this.teamModels) ? this.teamModels.length : 0;
      utils.updateIntercom(this.loggedInUser, this.activeTeam, state.model.activeModel, totalOfferings);
      switch (mutations.type) {
        // when the solution changes
        case "model/ActiveModel":
          if (state.model.activeModel) {
            // If there is a modelId in query and it's outdated, it needs to be replaced with the current one (avoids page refresh loop)
            const queryModelId = this.$route.query?.modelId;
            if (queryModelId && queryModelId !== state.model.activeModel._id) {
              this.$router.replace({
                query: {
                  ...this.$route.query,
                  modelId: state.model.activeModel._id,
                },
              });
            }
            // determine if we can stay on this page after the solution changes
            // Ideally all pages would work, but we need to add support for specific pages as we deal with the nuances
            const stay = routeToStayOn(this.$route, state.model.activeModel._id);
            try {
              // reset pinia stores
              const savedAccountsStore = useSavedAccounts();
              const savedContactsStore = useSavedContacts();
              const integrationsStore = useIntegrations();

              savedAccountsStore.$reset();
              // triggers an empty action that can be subscribed to. Wasn't able to find a way to detect when the store is reset
              savedAccountsStore.resetState();
              savedContactsStore.$reset();
              // triggers an empty action that can be subscribed to. Wasn't able to find a way to detect when the store is reset
              savedContactsStore.resetState();

              integrationsStore.$reset();

              this.setTeamUsers(undefined);
              utils.setUserProperties(this.loggedInUser, this.activeTeam, state.model.activeModel);
              utils.updateIntercom(this.loggedInUser, this.activeTeam, state.model.activeModel, totalOfferings);
              if ((window as any).mixpanel && (window as any).mixpanel.app) {
                (window as any).mixpanel.app.reset;

                utils.setMixPanelUserProperties(this.loggedInUser, this.activeTeam, state.model.activeModel, this.userTeams);
              }
            } catch (ex) {
              console.error("Unknown analytics failure " + ex);
            }
            if (state.model.activeModel && state.model.activeModel._id) {
              utils.sendMixPanelEvent("UserAccountFlags", this.activeTeam.user_account_flags);
              this.setActiveIntegrations(null);
            }
            if (this.redirect) {
              this.$router.push(this.redirect);
              this.unsetRedirect();
              return;
            } else if (stay) {
              if (stay === "reload") {
                // show a loader while we wait
                this.showLoadingModal = true;
                // reloads the current screen
                this.$router.go(0);
                return;
              }
              // keep query to keep filter params
              if ((stay as any).name && ["savedcontacts", "saveddatasets", "insights", "insights-technologies", "insights-people"].includes((stay as any).name)) {
                const stayWithQuery = {
                  ...stay,
                  query: {
                    ...this.$route.query,
                    modelId: state.model.activeModel._id,
                  },
                };
                this.$router.push(stayWithQuery);
              } else this.$router.push(stay);
              return;
            } else {
              this.showLoadingModal = true;
              const savedSearches = await this.discoverStore.getOwnedSearches();
              // if there are saved searches redirect to Engage
              if (savedSearches?.length > 0 && !(this.userStore.hasDashboardPage && this.isAdminOrManager)) {
                this.$router.push({
                  path: "/saved-searches",
                });
                this.showLoadingModal = false;
                return;
              }
              this.showLoadingModal = false;
              if (this.userStore.hasDashboardPage && this.isAdminOrManager) {
                this.$router.push({
                  name: "dashboard",
                });
              } else {
                this.$router.push({
                  name: "filteraccounts",
                  query: { modelId: state.model.activeModel._id },
                });
              }
            }
          }
      }
    });
    unsubscribeTeamSelection = this.$store.subscribe((mutations, state) => {
      switch (mutations.type) {
        case "team/ActiveTeam":
          if (!state.team.activeTeam) {
            break;
          }
          const getModels = async () => {
            try {
              const models = await this.getTeamModels();
              if (!models || !Array.isArray(models) || !models.length) {
                this.setActiveModel(undefined);
                this.$router.push({
                  name: "modelSelection",
                });
                return;
              }
              if (models.length > 1) {
                // sets active model and redirect if theres a valid modelId in redirect query
                if (this.redirect?.query?.modelId) {
                  const modelId = this.redirect.query.modelId;
                  const foundModel = models.find((model) => model._id === modelId);
                  if (foundModel) {
                    // set active model mutation subscription will take care of redirection
                    this.setActiveModel(foundModel);
                  } else {
                    this.setActiveModel(models[0]);
                  }
                } else {
                  // if theres more than one model, redirects to model selection page
                  this.$router.push({
                    name: "modelSelection",
                  });
                }
              } else {
                // if single model and no redirect sets the model and go to search page
                if (!this.redirect) {
                  this.setActiveModel(models[0]);
                  if (this.userStore.hasDashboardPage && this.isAdminOrManager) {
                    this.$router.push({
                      name: "dashboard",
                    });
                  } else {
                    this.$router.push({
                      name: "filteraccounts",
                    });
                  }
                } else {
                  // if redirection model selection subscription takes care of redirection
                  this.setActiveModel(models[0]);
                }
              }
            } catch (err) {
              console.error("error: teamSelection subscribe in navbar getTeamModels", err);
            }
          };
          this.getJWT({ accountId: this.accountId, teamId: this.teamId, apiKey: this.user.api_key })
            .then(() => {
              this.getTeamFeatures(this.teamId).then((data: any) => {
                this.setTeamFeatures(data);
              });
              const user: User = this.loggedInUser;
              if (user && user.can(Rights.ShowPayment, state.team.activeTeam)) {
                this.$router.push({
                  name: "subscription",
                });
              } else {
                getModels();
              }
            })
            .catch((err: any) => {
              if (err && err.internal_code === 471) {
                this.$router.push({
                  name: "loginEmail",
                });
              }
            });

          break;
      }
    });
  },
  beforeUnmount(): void {
    unsubscribeModelSelection();
    unsubscribeTeamSelection();
  },
  methods: {
    ...mapActions({
      unsetRedirect: "login/unsetRedirect",
      setTeamUsers: "team/setTeamUsers",
      setActiveIntegrations: "integrations/setActiveIntegrations",
      getJWT: "login/getJWT",
      getTeamModels: "model/getTeamModels",
      setActiveModel: "model/setActiveModel",
      getTeamFeatures: "team/getTeamFeatures",
      setTeamFeatures: "team/setTeamFeatures",
      setUser: "login/setUser",
    }),
    canFeatureRedeemAccounts(): boolean {
      const user: User = this.loggedInUser;
      return !!(user && this.featuresLimit && (user.canFeature(Rights.AccountRedemptionBulk, this.featuresLimit) || user.canFeature(Rights.AccountRedemptionIndividual, this.featuresLimit)));
    },
    getSegmentationData(): any {
      return {
        email: this.loggedInUser?.email,
        name: this.loggedInUser?.name,
        role: this.loggedInUser?.role,
        team: this.teamName,
        member_type: this.loggedInUser?.organization,
      };
    },
    getUserData(): any {
      let user = {
        id: this.loggedInUser?.id,
        email: this.loggedInUser?.email,
        name: this.loggedInUser?.name,
      };
      return user;
    },
    async updateUserData() {
      // Get user details from new API and set on store
      if (!this.loggedInUser) {
        return;
      }
      const userData = await this.userStore.getUser(this.loggedInUser.id);
      // Do not use data if BE fails
      if (!userData) {
        return;
      }
      await this.setUser({
        ...this.loggedInUser,
        name: userData.name,
        job_title: userData.job_title,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#headerNav {
  max-height: 34px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 101;
}

.model-name-container {
  border-radius: 2px;
  border: solid 1px #333c44;
  margin-left: 10px;
}

.label {
  font-size: 0.69rem;
  color: #98a0aa;
  border-right: 2px solid #333c44;
}

.model-name {
  font-size: 0.88rem;
  color: #ffffff;
  font-weight: 300;
  opacity: 1;
  text-align: left;
}

.brand-name {
  display: inline-block;
  padding: 4px 0px 0px 10px;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.2rem;
}

.brand-name-white {
  display: inline-block;
  padding: 4px 0 0 10px;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.2rem;
}

.publish-btn {
  color: #ffffff;
  border: 1px solid $gray-dark;
  padding: 0;
  border-radius: 2px;
  text-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: transparent;
  font-size: 0.69rem;
  opacity: 0.4;
}

.publish-btn:disabled {
  cursor: default;
}

.publish-btn:hover:enabled,
.publish-btn:focus:enabled {
  opacity: 1;
  background-color: transparent;
}

.publish-btn:hover:disabled {
  pointer-events: none;
  cursor: default;
}

input,
textarea {
  border-radius: 2px !important;
  background-color: #ffffff !important;
  border: solid 1px $gray-light !important;
}

input:focus,
textarea:focus {
  border: solid 1px #91a1b0 !important;
}

.li-button {
  margin-bottom: 5px;
}

.team-name-container {
  margin-left: 10px;
  border-radius: 2px;
  border: solid 1px #ffffff;
  font-size: 0.69rem;
}

.team-name {
  font-size: 0.88rem;
  color: #ffffff;
  opacity: 0.75;
}

.team-label {
  color: #ffffff;
  border-right: 2px solid #ffffff;
}

.col-logo-dark {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;
}

.col-logo-dark .burger-icon {
  position: relative;
  top: -2px;
  margin: 0 10px 0 10px;
  cursor: pointer;
}

.col-logo-dark .enlyft-icon {
  position: relative;
  top: -2px;
  margin: 0 0px 0 10px;
}

.team-name-container-dark {
  border-radius: 2px;
  border: solid 1px #333c44;
}

.team-label-dark {
  font-size: 0.69rem;
  color: #98a0aa;
  border-right: 2px solid #333c44;
}

.dropdown-toggle::after {
  content: none;
}

.status-active {
  color: #86dc00;
  font-size: 0.75rem;
  border: solid 1px #86dc00;
  border-radius: 4px;
  width: 80px;
  text-align: center;
  padding: 0px !important;
}

.status-incomplete {
  color: #90a0b1;
  font-size: 0.8rem;
  border: solid 1px #90a0b1;
  border-radius: 4px;
  width: 80px;
  text-align: center;
  padding: 0px !important;
}

.dropdown-menu {
  border-radius: 5px;
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 11rem;
  padding: 1.17rem !important;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.175);
}

.dropdown-menu a {
  color: $gray-dark;
  font-size: 0.89rem;
}

.dropdown-menu a:hover {
  color: #2f8794 !important;
  background-color: rgba(192, 246, 241, 0.1);
}

.more-models-link,
.more-models-link:hover {
  color: #0888ef !important;
}

.navbar-refresh {
  background-color: #ffe7b5;
  font-size: 0.88rem;
  color: #2a333c;
  text-align: center;
}

.refresh-link {
  color: #27a0b6;
  text-decoration: underline;
}

#div-search {
  display: inline-block;
}
</style>

<style lang="scss">
.div-improve {
  color: #4f5f71 !important;
}

// navbar css
.navbar-brand,
.navbar-dark .navbar-brand {
  font-size: 0.88rem;
  font-weight: 600;
  color: #ffffff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  opacity: 0.8;
}

.navbar-dark .navbar-nav .nav-link {
  color: #98a0aa;
  font-size: 12px;
}

.bgdark {
  background-color: $gray-darkest !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  transition: background-color 300ms linear;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: background-color 300ms linear;
}

.navbar-help {
  justify-content: flex-end !important;
}

.announcekit-widget-badge {
  width: 0.9em !important;
  height: 0.9em !important;
  line-height: 0.9em !important;
  font-size: 0.7em !important;
  font-weight: bold !important;
}

.pointer {
  cursor: pointer;
  color: #4f5f71 !important;
}

.li-ak {
  min-width: 30px;
  padding-top: 5px;
  padding-bottom: 3px !important;
}

.hidden {
  display: none;
}
</style>
