import { StoreOptions, createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { loginStore } from "./pages/login/store";
import { myaccountsStore } from "./pages/engage/myaccounts/store";
import { sharedStore } from "./shared/store";
import { teamMemberStore } from "./pages/team/store";
import { setUpStore } from "./pages/discover/setup/store";
import { modelInsightStore } from "./pages/discover/insights/store";
import { modelStore } from "@/pages/model/store";
import utils from "@/shared/utils";
import { targetAccountsStore } from "@/pages/discover/targetAccounts/store";
import { adminStore } from "@/pages/admin_ui/store";
import { paymentStore } from "@/pages/payment/store";
import { integrationsStore } from "@/pages/integrations/store";

export interface IRootState {
  version: string;
  updateExist: boolean;
  needRefreshBar: boolean;
  needHardRefresh: boolean;
  refreshingApp: boolean;
  appNeedLogin: boolean;
  appReady: boolean;
  perfTrackers: any;
  clearStorage: boolean;
  triedUpdateErrorRefresh: boolean;
  utmParams: any;
  /* Modules. Needed for Typescript in vue 3. Should make these actually typed someday */
  team?: any;
  login?: any;
  shared?: any;
  model?: any;
  discover?: any;
}

const store: StoreOptions<IRootState> = {
  plugins: [
    createPersistedState({
      reducer(val, paths) {
        if (val.clearStorage) {
          console.info("Clearing session storage");
          return {};
        }
        // make persisted state as in paths
        const reducer: any = {};

        Object.entries(val).forEach((entry) => {
          const [key, value] = entry;

          const foundPaths = paths.filter((path) => {
            // exact match
            if (key === path) return true;
            // match on nested path (e.g. "discover.categories")
            else if (path.startsWith(`${key}.`)) return true;

            // no match
            return false;
          });

          if (foundPaths) {
            if (foundPaths.length > 1) {
              // nested path found (e.g. "discover.categories")
              // only store those specified

              reducer[key] = foundPaths.reduce((allProperties: any, path) => {
                // take a nested path like "discover.categories" and get the second part "categories"
                const storeProperty: string | undefined = path.split(".").pop();

                if (storeProperty === undefined) return allProperties;

                // if there's a value
                if (value[storeProperty] !== undefined) {
                  allProperties[storeProperty] = value[storeProperty];
                }

                return allProperties;
              }, {});
            } else {
              // direct match on a store path (e.g. "discover")
              reducer[key] = value;
            }
          }
        });

        return reducer;
      },
      // these paths will be persisted
      paths: ["myaccounts", "shared", "team", "setup", "modelinsights", "model", "targetAccounts", "adminStore", "payment", "integrations", "search"],
      getState: (key: any, value: any) => {
        try {
          return (value = window.sessionStorage.getItem(key)) && typeof value !== "undefined" ? JSON.parse(value) : undefined;
        } catch (err) {
          console.error("Failed to execute getItem on storage", err);
        }
        return undefined;
      },
      setState: (key: any, state: any) => {
        try {
          return window.sessionStorage.setItem(key, JSON.stringify(state));
        } catch (e: any) {
          const errorMessage = e.message ? e.message : "Failed to execute setItem on storage";
          console.error(errorMessage);
          if (e.code && e.code == 22 && !state.shared.quotaExceededErrorShown) {
            window.location.replace("/error?type=quotaexceeded");
            if ("storage" in navigator && "estimate" in navigator.storage) {
              navigator.storage.estimate().then(({ usage, quota }) => {
                console.info(`Using ${usage} out of ${quota} bytes.`);
              });
            }
          }
        }
      },
    }),
    createPersistedState({
      paths: ["login"],
      getState: (key: any, value: any) => {
        try {
          return (value = window.localStorage.getItem(key)) && typeof value !== "undefined" ? JSON.parse(value) : undefined;
        } catch (err) {
          console.error("Failed to execute getItem on storage", err);
        }
        return undefined;
      },
      setState: (key: any, state: any) => {
        try {
          return window.localStorage.setItem(key, JSON.stringify(state));
        } catch (e: any) {
          const errorMessage = e.message ? e.message : "Failed to execute setItem on storage";
          console.error(errorMessage);
          if (e.code && e.code == 22 && !state.shared.quotaExceededErrorShown) {
            window.location.replace("/error?type=quotaexceeded");
            if ("storage" in navigator && "estimate" in navigator.storage) {
              navigator.storage.estimate().then(({ usage, quota }) => {
                console.info(`Using ${usage} out of ${quota} bytes.`);
              });
            }
          }
        }
      },
    }),
  ],
  state: {
    version: "1.0.0",
    updateExist: false,
    refreshingApp: false,
    appReady: false,
    appNeedLogin: false,
    needRefreshBar: false,
    needHardRefresh: false,
    perfTrackers: {},
    clearStorage: false,
    triedUpdateErrorRefresh: false,
    utmParams: {
      utm_campaign: "",
      utm_source: "",
      utm_medium: "",
      utm_term: "",
      utm_content: "",
    },
  },
  modules: {
    login: loginStore,
    myaccounts: myaccountsStore,
    shared: sharedStore,
    team: teamMemberStore,
    setup: setUpStore,
    modelinsights: modelInsightStore,
    model: modelStore,
    targetAccounts: targetAccountsStore,
    adminStore: adminStore,
    payment: paymentStore,
    integrations: integrationsStore,
  },
  mutations: {
    setAppReady(state) {
      state.appReady = true;
    },
    setAppNeedLogin(state, status) {
      state.appNeedLogin = status;
    },
    setClearStorage(state, status) {
      state.clearStorage = status;
    },
    setUtmData(state, params) {
      state.utmParams = params;
    },
  },
  actions: {
    setAppReady({ commit }) {
      commit("setAppReady");
    },
    setAppNeedLogin({ commit }, status) {
      commit("setAppNeedLogin", status);
    },
    setClearStorage({ commit }, status) {
      commit("setClearStorage", status);
    },
    setUpdateExist(store) {
      console.info("updateExist");
      store.state.updateExist = true;
    },
    setRefreshing(store) {
      console.info("refreshingApp");
      store.state.refreshingApp = true;
    },
    setNeedRefreshBar(store) {
      console.info("needRefreshBar");
      store.state.needRefreshBar = true;
    },
    resetUpdateChecks(store) {
      store.state.updateExist = false;
      store.state.needHardRefresh = false;
      store.state.refreshingApp = false;
      store.state.needRefreshBar = false;
    },
    setNeedHardRefresh(store) {
      console.info("needHardRefresh");
      store.state.needHardRefresh = true;
    },
    refreshApp(store) {
      if (store.state.refreshingApp) {
        return;
      }
      store.state.refreshingApp = true;
      console.info("page reload refreshApp");
      window.location.reload();
    },
    startTrackingPerf(store, key) {
      store.state.perfTrackers[key] = { start: new Date().getTime() };
    },
    trackPerf(store, { key, val }) {
      try {
        const x = store.state.perfTrackers[key];
        if (!x || !x.start) {
          console.error("trackPerf missing start", key);
          return;
        }
        utils.setGACustomEvent(store.getters["login/getUser"], store.getters["team/activeTeam"], key, val, new Date().getTime() - x.start);
        store.state.perfTrackers[key] = {};
      } catch (ex) {
        console.error("Unknown analytics failure (trackPerf)" + ex);
      }
    },
    setUtmData({ commit }, params) {
      commit("setUtmData", params);
    },
    tryUpdateErrorRefresh(store) {
      if (!store.state.triedUpdateErrorRefresh) {
        store.state.triedUpdateErrorRefresh = true;
        window.location.reload();
      } else {
        console.error('Trying to reload the page more than once after "loading chunk error".');
      }
    },
    resetUpdateErrorRefresh(store) {
      store.state.triedUpdateErrorRefresh = false;
    },
  },
};

export default createStore(store);
