import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-352fa43c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-text-center c-mt3 c-px4" }
const _hoisted_2 = { id: "headerNav" }
const _hoisted_3 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_typography = _resolveComponent("c-typography")!
  const _component_c_circular_progress = _resolveComponent("c-circular-progress")!
  const _component_c_modal = _resolveComponent("c-modal")!
  const _component_AnnounceKit = _resolveComponent("AnnounceKit")!
  const _component_refresh_bar = _resolveComponent("refresh-bar")!
  const _component_c_s_user_bar = _resolveComponent("c-s-user-bar")!
  const _component_unsupported_browser_bar = _resolveComponent("unsupported-browser-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_c_modal, {
      modelValue: $data.showLoadingModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.showLoadingModal) = $event))
    }, {
      body: _withCtx(() => [
        _createVNode(_component_c_typography, { center: "" }, {
          default: _withCtx(() => [
            _createTextVNode("Loading")
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_c_circular_progress, {
            color: "#1F2929",
            size: "xs"
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AnnounceKit, {
          widget: "https://support.enlyft.com/widgets/v2/4B6XXG",
          user: $options.getUserData(),
          data: $options.getSegmentationData()
        }, null, 8, ["user", "data"])
      ]),
      ($options.showRefreshBar)
        ? (_openBlock(), _createBlock(_component_refresh_bar, { key: 0 }))
        : _createCommentVNode("", true),
      ($options.showCSUserBar)
        ? (_openBlock(), _createBlock(_component_c_s_user_bar, {
            key: 1,
            teamName: _ctx.activeTeam.account_name
          }, null, 8, ["teamName"]))
        : _createCommentVNode("", true),
      ($options.showUnsupportedBrowserBar)
        ? (_openBlock(), _createBlock(_component_unsupported_browser_bar, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ]))
}